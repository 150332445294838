import Head from 'next/head'
import { ErrorPageV2, Layout } from 'components'
import { NextPageWithLayout } from 'shared/interfaces/Layout'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import CurtainWrapper from 'components/CurtainWrapper'

const Custom404: NextPageWithLayout = () => {
  return (
    <>
      <Head>
        <title>Página no encontrada | Unimarc</title>
        <meta
          content='noindex'
          name='robots'
        />
      </Head>
      <ErrorPageV2 errorType='404' />
    </>
  )
}

export default Custom404

Custom404.getLayout = (page) => {
  return (
    <CurtainWrapper>
      <Layout backgroundColor={getGlobalStyle('--color-background-white')}>
        {page}
      </Layout>
    </CurtainWrapper>
  )
}
